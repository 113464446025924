import React, { useContext } from "react";

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import ContactFormContainer from "../../../common/ContactFormContainer";
import { templateVariableValue } from "../../../helpers/templateVariables";
import * as theme5Styles from "../scss/theme5.module.scss";

import userPhoto from "../images/user-image.jpg";
import userIcon from "../images/user-icon.png";
import buildingIcon from "../images/building-icon.png";
import locationIcon from "../images/location-icon.png";
import phoneIcon from "../images/phone.png";
import emailIcon from "../images/mail.png";
import webIcon from "../images/www.png";

const Contact = () => {
  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          templateConfiguration,
          template: { templateVariables },
          setting: display_settings,
          company,
          contact_detail_user: user,
        },
      },
    },
    currentLocaleKey: locale,
    defaultLocaleKey: defaultLocale,
  } = siteData;

  const contactLogo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "contact-logo",
    locale,
    defaultLocale
  );

  const {
    display_company_name,
    display_contact_name,
    display_contact_email,
    display_contact_phone,
    display_contact_website,
    display_contact_address,
  } = display_settings;

  if (user) {
    const { socialProfiles: social_profiles } = user;
    let phone = user.countryCode + user.mobile_phone;
    return (
      <div className={`${theme5Styles.contactRow}`}>
        <div className={theme5Styles.colm}>
          <div className={`${theme5Styles.userPhoto}`}>
            <img src={contactLogo || user.profile_image || userPhoto} alt="" />
          </div>
        </div>
        <div className={theme5Styles.colm}>
          {display_contact_name && (
            <span className={`${theme5Styles.contactInfo}`}>
              <img src={userIcon} alt="" />
              {user.name}
            </span>
          )}
          {display_contact_phone && (
            <span className={`${theme5Styles.contactInfo}`}>
              <img src={phoneIcon} alt="" />
              {phone}
            </span>
          )}
          {display_contact_email && (
            <span className={`${theme5Styles.contactInfo}`}>
              <img src={emailIcon} alt="" />
              {user.email}
            </span>
          )}
          {social_profiles.length > 0 && (
            <ul className={`${theme5Styles.social}`}>
              {social_profiles.map(({ type, profile }) => {
                return (
                  <li key={type}>
                    <a href={profile} target="_blank" rel="noopener noreferrer">
                      <img
                        src={require(`../images/social/${type}.png`).default}
                        alt={type}
                      />
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div className={theme5Styles.colm}>
          <ContactFormContainer />
        </div>
      </div>
    );
  } else {
    const { socialProfiles: social_profiles } = company;

    return (
      <div className={`${theme5Styles.contactRow}`}>
        {(contactLogo || company.logo) && (
          <div className={theme5Styles.colm}>
            <div className={`${theme5Styles.userPhoto}`}>
              <img src={contactLogo || company.logo} alt="" />
            </div>
          </div>
        )}
        <div className={theme5Styles.colm}>
          {display_company_name && (
            <span className={`${theme5Styles.contactInfo}`}>
              <img src={buildingIcon} alt="" />
              {company.name}
            </span>
          )}
          {display_contact_address && (
            <span className={`${theme5Styles.contactInfo}`}>
              <img src={locationIcon} alt="" />
              {company.address},<br />
              {company.city},{company.state}-{company.postal_code},<br />
              {company.country ? company.country.country_name : ""}
            </span>
          )}
          {display_contact_phone && (
            <span className={`${theme5Styles.contactInfo}`}>
              <img src={phoneIcon} alt="" />
              {company.phone}
            </span>
          )}
          {display_contact_email && (
            <span className={`${theme5Styles.contactInfo}`}>
              <img src={emailIcon} alt="" />
              {company.email}
            </span>
          )}
          {display_contact_website && (
            <span className={`${theme5Styles.contactInfo}`}>
              <img src={webIcon} alt="" />
              <a
                href={company.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                {company.website}
              </a>
            </span>
          )}
          {social_profiles.length > 0 && (
            <ul className={`${theme5Styles.social}`}>
              {social_profiles.map(({ type, profile }) => {
                return (
                  <li key={type}>
                    <a href={profile} target="_blank" rel="noopener noreferrer">
                      <img
                        src={require(`../images/social/${type}.png`).default}
                        alt={type}
                      />
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div className={theme5Styles.colm}>
          <ContactFormContainer />
        </div>
      </div>
    );
  }
};

export default Contact;
